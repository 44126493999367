<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Clientes </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item label="Prospectos" icon="briefcase-account-outline">
        <InterestedsList />
      </b-tab-item>
      <b-tab-item label="Clientes" icon="account-tie">
        <ClientsList />
      </b-tab-item>
      <b-tab-item
        label="Agrupación de crédito de clientes"
        icon="account-group"
      >
        <ClientsCreditGroupList />
      </b-tab-item>
      <b-tab-item label="Desbloqueo de clientes" icon="lock">
        <ClientsUnlocksList />
      </b-tab-item>
      <b-tab-item label="Catálogo de usos del CFDI" icon="domain">
        <CfdiUsesList />
      </b-tab-item>
      <b-tab-item label="Catálogo de regimenes" icon="briefcase-outline">
        <RegimenesList />
      </b-tab-item>
      <b-tab-item label="Catálogo de giros" icon="briefcase-search-outline">
        <CommercialBusinessList :type="0" />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import InterestedsList from "../components/InterestedsList";
import ClientsList from "../components/ClientsList";
import ClientsCreditGroupList from "../components/ClientsCreditGroupList";
import ClientsUnlocksList from "../components/ClientsUnlocksList";
import CfdiUsesList from "../components/CfdiUsesList";
import RegimenesList from "../components/RegimenesList";
import CommercialBusinessList from "../components/CommercialBusinessList";
export default {
  name: "AllClientsList",
  components: {
    InterestedsList,
    ClientsList,
    ClientsCreditGroupList,
    ClientsUnlocksList,
    CfdiUsesList,
    RegimenesList,
    CommercialBusinessList,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "clients");
  },
  async created() {
    let allPromises = [];

    allPromises.push(this.$store.dispatch("GETCLIENTCREDITGROUP"));
    allPromises.push(this.$store.dispatch("GETCOMMERCIALBUSINESS"));
    if (this.$store.getters.SATCFDIUSES.length === 0) {
      allPromises.push(this.$store.dispatch("GETSATCFDIUSES"));
    }
    if (this.$store.getters.SATREGIMENES.length === 0) {
      allPromises.push(this.$store.dispatch("GETSATREGIMENES"));
    }
    allPromises.push(this.$store.dispatch("GETORGANIZATIONBOARD"));
    allPromises.push(this.$store.dispatch("GETCLIENTSUNLOCKS"));
    if (this.$store.getters.PAYMENTTYPES.length === 0) {
      allPromises.push(this.$store.dispatch("GETPAYMENTTYPES"));
    }

    await Promise.all(allPromises);
  },
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
