/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button type="is-primary" @click="openAddCommercialBusiness()"
          >Agregar giro comercial</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="refreshInfo()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="global-list-main-container">
      <div class="table-header">
        Cantidad de giros comerciales: <b>{{ commercialBusiness.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="commercialBusiness"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="cfdiUses"
        :checked-rows.sync="checkedRegimen"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.DESCRIPCION_GIR}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        default-sort="GIRO_EMP"
      >
        <template #empty>
          <div class="has-text-centered">No hay giros comerciales</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span v-if="column.money">{{
              props.row[column.field] | money("MXN", 2)
            }}</span>
            <span v-if="!column.money">{{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <AddCommercialBusiness
                  :commercialBusinessInformation="props.row"
                />
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import AddCommercialBusiness from "./AddCommercialBusiness";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "CommercialBusinessList",
  props: ["type"],
  components: {
    AddCommercialBusiness,
  },
  directives: { cleave },
  data() {
    return {
      perPage: 50,
      checkedRegimen: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "GIRO_EMP",
          label: "Giro empresarial",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION_GIR",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "typeFormatted",
          label: "Tipo",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      masks: Masks,
      refreshLoading: false,
    };
  },
  async created() {},
  methods: {
    async refreshInfo() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETCOMMERCIALBUSINESS"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    openAddCommercialBusiness() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddCommercialBusiness,
        props: {
          type: this.type,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    commercialBusiness() {
      return this.$store.getters.COMMERCIALBUSINESS.map(
        (singleCommercialBusiness) => {
          const commercialBusinessWithProps = { ...singleCommercialBusiness };
          if (commercialBusinessWithProps.TIPO === "C") {
            commercialBusinessWithProps.typeFormatted = "Clientes";
          } else if (commercialBusinessWithProps.TIPO === "P") {
            commercialBusinessWithProps.typeFormatted = "Proveedores";
          }
          return commercialBusinessWithProps;
        }
      ).filter((singleCommercialBusiness) => {
        return this.type === 0
          ? singleCommercialBusiness.TIPO === "C"
          : singleCommercialBusiness.TIPO === "P";
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
