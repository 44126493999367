/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button type="is-primary" @click="openAddClient()"
          >Agregar cliente o prospecto</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="global-list-main-container">
      <div class="table-header">
        Cantidad de clientes: <b>{{ clients.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="clients"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="Brands"
        :checked-rows.sync="checkedClients"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CLAVE_CLI}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        default-sort="CLAVE_CLI"
      >
        <template #empty>
          <div class="has-text-centered">No hay clientes</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span v-if="column.money">{{
              props.row[column.field] | money("MXN", 2)
            }}</span>
            <span v-if="!column.money">{{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <AddClient :clientInformation="props.row" />
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import ImportFileModal from "../../Global/components/ImportFileModal";
import AddClient from "./AddClient.vue";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "InterestedsList",
  components: {
    AddClient,
  },
  directives: { cleave },
  data() {
    return {
      perPage: 50,
      checkedClients: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_CLI",
          label: "Clave del cliente",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "defaultPriceList",
          label: "Lista de precios",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_EMPRESA",
          label: "Razón social",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "RFC_CLI",
          label: "RFC",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_CLI",
          label: "Nombre",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "A_PATERNO",
          label: "Apellido paterno",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "A_MATERNO",
          label: "Apellido materno",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "LIMITE_CRED",
          label: "Limite de crédito",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "SALDO",
          label: "Saldo actual de crédito",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "DIRECCION",
          label: "Direción",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      masks: Masks,
    };
  },
  async created() {},
  methods: {
    async reloadInformation() {
      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETCOMMERCIALBUSINESS"));

      if (this.$store.getters.SATCFDIUSES.length === 0) {
        allPromises.push(this.$store.dispatch("GETSATCFDIUSES"));
      }

      if (this.$store.getters.SATREGIMENES.length === 0) {
        allPromises.push(this.$store.dispatch("GETSATREGIMENES"));
      }

      allPromises.push(this.$store.dispatch("GETORGANIZATIONBOARD"));
      allPromises.push(this.$store.dispatch("GETCLIENTSUNLOCKS"));
      allPromises.push(this.$store.dispatch("GETWAREHOUSESNOTRANSFERS"));
      allPromises.push(this.$store.dispatch("GETPRICESLISTS"));

      await Promise.all(allPromises);
    },
    openAddClient() {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddClient,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    startImport() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ImportFileModal,
        props: {
          importType: "clients",
          nameImport: "Clientes",
          afterAction: "GETCLIENTS",
        },
        hasModalCard: false,
        customClass: "import-provider-modal-class",
        trapFocus: true,
        width: "50%",
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    clients() {
      return this.$store.getters.CLIENTS.map((singleClient) => {
        const clientWithProps = { ...singleClient };
        if (clientWithProps.LISTA_PRECIOS_PRE) {
          clientWithProps.defaultPriceList =
            clientWithProps.LISTA_PRECIOS_PRE.CLAVE_LISTAP;
        }
        return clientWithProps;
      }).filter((singleClient) => {
        return singleClient.TIPO === "PR";
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
