<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">
      Agregar agrupación de crédito de clientes
    </h1>

    <div class="control add-global-controls">
      <b-button
        type="is-primary"
        class="special-margin"
        @click="pushGroupClient()"
        >Agregar cliente extra</b-button
      >
    </div>

    <div
      v-if="!clientCreditGroupInformation"
      class="global-form-hint-container"
    >
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span> Los clientes con
      campos vacios seran
      <span class="global-form-hint-color"> ignorados. </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Descripción (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': clientCreditGroupRequiredErrors.DESCRIPCION }"
        :message="{
          'La descripción no es válida':
            clientCreditGroupRequiredErrors.DESCRIPCION,
        }"
      >
        <b-input
          placeholder="Ejemplo: Marca 1"
          expanded
          icon="numeric"
          v-model="newClientCreditGroup.DESCRIPCION"
          required
        ></b-input>
      </b-field>
    </b-field>

    <div v-for="(item, index) in groupClients" :key="index">
      <h3 class="form-division">Cliente {{ index + 1 }}</h3>

      <b-field grouped class="special-margin">
        <b-field
          label="Cliente (Requerido)"
          expanded
          label-position="inside"
          class="required-input"
          required
        >
          <b-autocomplete
            autocomplete
            :data="autoCompleteClients(item.CLIENTE)"
            field="NOMBRE_EMPRESA"
            v-model="item.CLIENTE"
            open-on-focus
            placeholder="Ejemplo: Cliente 1 "
            expanded
            icon="account-tie"
            clearable
            @select="
              (option) => (item.CLAVE_CLIENTE = option ? option._id : undefined)
            "
            check-infinite-scroll
          >
            <template #empty>No se encontraron clientes</template>
          </b-autocomplete>
        </b-field>
      </b-field>
    </div>

    <div class="add-global-controls" v-if="clientCreditGroupInformation">
      <b-button type="is-success" @click="saveClient(1)"
        >Actualizar agrupación de crédito de clientes</b-button
      >
      <b-button
        type="is-danger"
        @click="startDeleteClientCreditGroup(clientCreditGroupInformation)"
        class="cancel-button"
        >Borrar agrupación de crédito de clientes</b-button
      >
    </div>

    <div class="add-global-controls" v-if="!clientCreditGroupInformation">
      <b-button type="is-success" @click="saveClient(0)"
        >Añadir agrupación de crédito de clientes</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "@/utils/fns";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";
import {
  fillObjectForm,
  openConfirmationModalDanger,
} from "@/utils/component-fns";

// @ is an alias to /src
export default {
  name: "AddClientCreditGroup",
  props: ["clientCreditGroupInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      typeSelected: [],
      newClientCreditGroup: {
        DESCRIPCION: "",
      },
      groupClients: [],
      clientCreditGroupRequiredErrors: {
        DESCRIPCION: false,
      },
      articleDescription: "",
      currentGroupClient: 0,
    };
  },
  mounted() {
    // Fill the formula information in form fields
    if (this.clientCreditGroupInformation) {
      // Fill the form with received information
      fillObjectForm(
        this.clientCreditGroupInformation,
        this.newClientCreditGroup
      );

      this.newClientCreditGroup._id = this.clientCreditGroupInformation._id;

      for (const [
        singleClientIndex,
        singleClient,
      ] of this.clientCreditGroupInformation.CLAVE_CLIENTES.entries()) {
        this.pushGroupClient();

        this.groupClients[singleClientIndex].CLAVE_CLIENTE = singleClient._id;
        this.groupClients[singleClientIndex].CLIENTE =
          singleClient.NOMBRE_EMPRESA;
      }
    } else {
      this.pushGroupClient();
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    pushGroupClient() {
      let tempGroupClient = {
        CLAVE_CLIENTE: undefined,
        CLIENTE: "",
      };
      this.groupClients.push(tempGroupClient);
      this.currentGroupClient++;
    },
    validateClientInformation() {
      for (const singleGroupClient of this.groupClients) {
        for (const singleClient of this.clients) {
          if (
            singleGroupClient.CLAVE_CLIENTE.toString() ===
            singleClient._id.toString()
          ) {
            if (
              singleClient.CLAVE_AGRUPACION ||
              singleClient.BALANCE_CRED > singleClient.LIMITE_CRED
            ) {
              return false;
            }
          }
        }
      }
      return true;
    },
    validateClientInformationDelete() {
      for (const singleGroupClient of this.groupClients) {
        for (const singleClient of this.clients) {
          if (
            singleGroupClient.CLAVE_CLIENTE.toString() ===
            singleClient._id.toString()
          ) {
            if (singleClient.BALANCE_CRED > singleClient.LIMITE_CRED) {
              return false;
            }
          }
        }
      }
      return true;
    },
    /**
     * @desc saves or updates a client group in the database depending on type
     */
    async saveClient(type) {
      try {
        this.removeNotValid();
        this.clientCreditGroupRequiredErrors = validateFields(
          this.newClientCreditGroup,
          this.clientCreditGroupRequiredErrors
        );
        if (!this.validateClientInformation()) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al guardar la agrupación revise que los clientes no esten en otra agrupación y que no deban más del limite de crédito disponible`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        if (checkValidationErrors(this.clientCreditGroupRequiredErrors)) {
          let response = await this.$store.dispatch(
            type === 0 ? "SAVECLIENTCREDITGROUP" : "UPDATECLIENTCREDITGROUP",
            {
              ...this.newClientCreditGroup,
              CLAVE_CLIENTES: this.groupClients.map((singleClient) => {
                return singleClient.CLAVE_CLIENTE;
              }),
            }
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente la agrupación",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar la agrupación en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar la agrupación en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar la agrupación en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    autoCompleteClients(value) {
      if (!this.clients) {
        return [];
      }
      return this.clients
        .filter((singleClient) => {
          return (
            singleClient.CLAVE_CLI.toString()
              .toLowerCase()
              .indexOf(value.toLowerCase()) >= 0 ||
            singleClient.NOMBRE_EMPRESA.toString()
              .toLowerCase()
              .indexOf(value.toLowerCase()) >= 0
          );
        })
        .filter((singleClient) => {
          return !this.groupClients.some((singleGroupClient) => {
            return (
              singleGroupClient.CLAVE_CLIENTE &&
              singleGroupClient.CLAVE_CLIENTE.toString() ===
                singleClient._id.toString()
            );
          });
        });
    },
    removeNotValid() {
      this.groupClients = this.groupClients.filter((singleClient) => {
        if (!singleClient.CLAVE_CLIENTE) return false;
        return true;
      });
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    startDeleteClientCreditGroup(clientCreditGroupInformation) {
      this.startCancelEvent(
        "danger",
        () => {
          this.deleteClientCreditGroup(clientCreditGroupInformation);
        },
        "Borras agrupación"
      );
    },
    async deleteClientCreditGroup(clientCreditGroupInformation) {
      try {
        if (!this.validateClientInformationDelete()) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al borrar la agrupación revise que los clientes no deban más del limite de crédito disponible`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }

        let response = await this.$store.dispatch("DELETECLIENTCREDITGROUP", {
          clientCreditGroup: clientCreditGroupInformation._id,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se borró exitosamente la agrupación",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al borrar la agrupación en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al borrar la agrupación en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {
    clients() {
      return this.$store.getters.CLIENTS.map((singleClient) => {
        const clientWithProps = { ...singleClient };
        if (clientWithProps.LISTA_PRECIOS_PRE) {
          clientWithProps.defaultPriceList =
            clientWithProps.LISTA_PRECIOS_PRE.CLAVE_LISTAP;
        }
        return clientWithProps;
      });
    },
  },
  watch: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
